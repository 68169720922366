<template>
	<div class="goodsList">
		<!-- <div class="ad">
			<img class="regular" src="../../assets/sources/banner.png">
		</div> -->
		<div class="cate">
			{{$store.state.cateArr.find(item=>{return item.categoryId == this.$route.query.cateId}).categoryName}}
		</div>
		<div class="filter">
			<div class="main">
				<div class="options">
					<div class="item" @click="currentCate = $route.query.cateId">{{$t('tip.qb')}}</div>
					<div class="devide"></div>
					<template v-for="val in sons">
						<div class="item" @click="currentCate = val.categoryId">{{val.categoryName}}</div>
						<div class="devide"></div>
					</template>
					<!-- <div class="devide"></div> -->
					<!-- 	<div class="item">排列方式</div>
					<div class="devide"></div>
					<div class="item">筛选器</div>
					<div class="item">颜色</div>
					<div class="item">尺寸</div>
					<div class="item">系列</div> -->
				</div>
				<!-- <div class="total">{{total}}{{$t('cart.unit')}}{{$t('cart.good')}}</div> -->
				<div class="sort" v-if="$store.state.platform == 'pc'">
					<div class="item" :class="{'active':sort_order == ''}" @click="changeSort('')">
						{{$t('goodslists.tab_default')}}
					</div>
					<div class="item" :class="{'active':sort_order == 'onsaleDesc'}" @click="changeSort('onsaleDesc')">
						{{$t('goodslists.tab_xiaol')}}
						<em class="fs-down"><i class="arrow"></i></em>
					</div>
					<div class="item" :class="{'active':sort_order == 'soldTotalDesc'}"
						@click="changeSort('soldTotalDesc')">
						{{$t('goodslists.tab_new')}}
						<em class="fs-down"><i class="arrow"></i></em>
					</div>
					<div class="item" :class="{'active':sort_order == 'priceDesc'|| sort_order == 'priceAsc'}"
						@click="changeSort(sort_order == 'priceDesc'?'priceAsc':'priceDesc')">
						{{$t('goodslists.tab_price')}}
						<em class="fs-down">
							<i class="arrow-top" :class="{'curr':sort_order == 'priceAsc'}"></i>
							<i class="arrow-bottom" :class="{'curr':sort_order == 'priceDesc'}"></i>
						</em>
					</div>
				</div>
			</div>
			<div class="sort m" v-if="$store.state.platform != 'pc'">
				<div class="item" :class="{'active':sort_order == ''}" @click="changeSort('')">
					{{$t('goodslists.tab_default')}}
				</div>
				<div class="item" :class="{'active':sort_order == 'onsaleDesc'}" @click="changeSort('onsaleDesc')">
					{{$t('goodslists.tab_xiaol')}}
					<em class="fs-down"><i class="arrow"></i></em>
				</div>
				<div class="item" :class="{'active':sort_order == 'soldTotalDesc'}"
					@click="changeSort('soldTotalDesc')">
					{{$t('goodslists.tab_new')}}
					<em class="fs-down"><i class="arrow"></i></em>
				</div>
				<div class="item" :class="{'active':sort_order == 'priceDesc'|| sort_order == 'priceAsc'}"
					@click="changeSort(sort_order == 'priceDesc'?'priceAsc':'priceDesc')">
					{{$t('goodslists.tab_price')}}
					<em class="fs-down">
						<i class="arrow-top" :class="{'curr':sort_order == 'priceAsc'}"></i>
						<i class="arrow-bottom" :class="{'curr':sort_order == 'priceDesc'}"></i>
					</em>
				</div>
			</div>
		</div>
		<div class="list">
			<div class="item" v-for="val in list">
				<GoodsItem :detail="val"></GoodsItem>
			</div>
		</div>
		<div class="more" @click="loadMore" v-if="list.length > 0">{{$t('nav.more')}}</div>
	</div>
</template>

<script>
	import GoodsItem from '../../components/Goods/listItem.vue'
	import Api from '../../api/request.js'
	export default {
		name: 'GoodsList',
		components: {
			GoodsItem
		},
		data() {
			return {
				page: {
					page: 1,
					size: 12
				},
				//排序规则,priceDesc,priceAsc,onsaleDesc,onsaleAsc,soldTotalDesc,soldTotalAsc
				sort_order: "",
				list: [],
				total: 0,
				currentCate: '',
				sons: []
			};
		},
		watch: {
			$route() {
				this.currentCate = this.$route.query.cateId;
				this.page.page = 1;
				this.findChild();
			},
			currentCate() {
				this.getList();
			}
		},
		created() {
			this.currentCate = this.$route.query.cateId;
			this.findChild();
		},
		methods: {
			//切换排序
			changeSort(sort_order) {
				this.sort_order = sort_order;
				//重新加载列表
				this.page.page = 1;
				this.getList();
			},
			findChild() {
				this.sons = this.$store.state.cateList.find(item => {
					return item.categoryId == this.$route.query.cateId;
				});
				this.sons = this.sons ? this.sons.sons : [];
			},
			getList() {
				console.log("加载");

				//先清空
				//this.list = [];
				//this.total = 0;

				this.$dialog.loading();
				Api.Goods.list({
					categoryId: this.currentCate,
					page: this.page.page,
					pageLimit: this.page.size,
					order: this.sort_order
				}).then(res => {
					if (this.page.page == 1) {
						this.list = res.data.data.list;
						this.total = res.data.data.totalLimits
					} else {
						if (res.data.data.list.length == 0) {
							this.$dialog.message(this.$t('tip.noMore'));
						} else {
							this.list = this.list.concat(res.data.data.list);
						}
					}
					this.$dialog.close();
				})
			},
			loadMore() {
				this.page.page += 1;
				this.getList();
			}
		}
	};
</script>

<style lang="less" scoped>
	.ad {
		height: 540px;
	}

	.cate {
		font-size: 28px;
		font-weight: bold;
		line-height: 40px;
		margin: 30px 0;
		text-align: center;
	}

	.filter {
		padding: 0 7px;

		.main {
			height: 56px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-top: 1px solid #757575;
			border-bottom: 1px solid #757575;

			.options {
				display: flex;
				align-items: center;

				.item {
					font-size: 14px;
					padding: 0 30px;
					cursor: pointer;
				}

				.devide {
					&:first-child {
						display: none;
					}

					&+.item {
						padding: 0 40px;
					}

					width: 1px;
					height: 30px;
					background: #BABABA;
				}
			}
		}

		.sort {
			display: flex;
			align-items: center;



			.item {
				border: 1px solid #666;
				padding: 5px 20px;
				border-right: 0px;
				color: #333;
				cursor: pointer;

				.fs-down,
				.fs-up {
					display: inline-block;
					width: 7px;
					margin-left: 5px;
					vertical-align: top;

					.arrow,
					.arrow-bottom,
					.arrow-top {
						float: left;
						width: 7px;
						overflow: hidden;
						background: url(../../assets/icon/sprite-arrow.png) no-repeat 0 -100px
					}

					.arrow {
						height: 11px;
						margin-top: 6px;
					}

					.arrow-top {
						height: 4px;
						margin-top: 6px;
						background-position: -10px -100px;

						&.curr {
							opacity: .5;
						}
					}

					.arrow-bottom {
						height: 4px;
						margin-top: 3px;
						background-position: -10px -110px;

						&.curr {
							opacity: .5;
						}
					}
				}

				&.active {
					background-color: #e4393c;
					color: #fff;

					.arrow {
						background-position: 0px -120px;
					}

					.arrow-top {
						background-position: -10px -120px;

						&.curr {
							opacity: .5;
						}
					}

					.arrow-bottom {
						background-position: -10px -130px;

						&.curr {
							opacity: .5;
						}
					}
				}
			}

			.item:last-child {
				border-right: 1px solid #666;
			}

			&.m {
				padding: 10px 0px;

				.item {
					padding: 2px 3px;
				}
			}
		}

		.total {
			font-size: 14px;
			margin-right: 40px;
		}
	}

	.list {
		display: flex;
		flex-wrap: wrap;
		padding: 0 0 60px 7px;

		.item {
			width: calc((100vw - 110px) / 4);
			margin: 50px 20px 0 0;
		}
	}

	.more {
		height: 50px;
		line-height: 50px;
		text-align: center;
		border: 2px solid #BBB;
		margin: 0 21px 84px;
		font-size: 16px;
		cursor: pointer;
	}

	@media screen and (max-width: 500px) {
		.ad {
			height: 28.1vw;
		}

		.cate {
			font-size: 4vw;
			font-weight: bold;
			margin: 4vw 0;
			text-align: center;
		}

		.filter {
			padding: 0 7px;

			.main {
				height: 12vw;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-top: 1px solid #757575;
				border-bottom: 1px solid #757575;

				.options {
					width: 100%;
					display: flex;
					align-items: center;

					.item {
						font-size: 3vw;
						padding: 0 !important;
						flex: 1;
						text-align: center;
					}

					.devide {
						display: none;
					}
				}
			}

			.total {
				display: none;
				font-size: 14px;
				margin-right: 40px;
			}
		}

		.list {
			display: flex;
			flex-wrap: wrap;
			padding: 0 0 6vw 6px;

			.item {
				width: calc((100vw - 18px) / 2);
				margin: 6px 6px 0 0;
			}
		}

		.more {
			height: 12vw;
			line-height: 13vw;
			border: 1px solid #BBB;
			font-size: 4vw;
		}
	}
</style>
